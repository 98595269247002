<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 14" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Product-detail" transform="translate(-1014.000000, -684.000000)" fill-rule="nonzero">
            <g id="email" transform="translate(1014.000000, 684.000000)">
                <polygon id="Path" points="13 7.59304997 20 12 20 3"/>
                <polygon id="Path" points="0 3 0 12 7 7.59304997"/>
                <path d="M18.782936,0 L1.21706399,0 C0.590966123,0 0.0941028858,0.487708948 0,1.11569977 L10,8 L20,1.11569977 C19.9058971,0.487708948 19.4090339,0 18.782936,0 Z" id="Path"/>
                <path d="M12.1209839,8.00124611 L10.3451305,9.16261682 C10.2397088,9.23115265 10.1204819,9.26479751 10,9.26479751 C9.87951807,9.26479751 9.76029116,9.23115265 9.65486948,9.16261682 L7.87901606,8 L0,12.9482866 C0.0966365462,13.5401869 0.591114458,14 1.21485944,14 L18.7851406,14 C19.4088855,14 19.9033635,13.5401869 20,12.9482866 L12.1209839,8.00124611 Z" id="Path"/>
            </g>
        </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: false
    }
  }
}
</script>
